import { isEmpty } from 'lodash'
import { call, takeEvery, put, takeLatest, debounce } from 'redux-saga/effects'
import { FilterActions } from '@root-gipro/modules/filter/models/interfaces/filter.actions'
import {
	setFilterResultCount,
	filtersRequest,
	isLoadingCountResult,
	setPresetFilterState,
	setInitFilterState,
	isSelectedPresetFilter,
	refreshFilterState,
	isSimilarProjectFilterState,
	resetFilterState,
	cleanUncCodeFromFilter,
	setSimilarProjectName,
	setVersionIprList,
} from '@root-gipro/modules/filter/store/actions'
import store from '@root-gipro/store'
import {
	setCommonProjectsCount,
	setCommonProjectsSum,
	setUncInfo,
	setAllowMoreProjects,
	setCommonProjectsPaginationCount,
	setFilterIdsCommonProjects,
	sortCommonProjects,
} from '@root-gipro/modules/commonProjects/store/actions'
import { ICommonProject, IUncInfo } from '@root-gipro/store/interfaces'

import { excludeData } from '@root-gipro/modules/filter/utils/exclude-data'
import { setPublicUncTechnicalSpecifications } from '@root-gipro/store/actions/public'
import { setFilterIdsPurchases } from '@root-gipro/modules/purchases/store/actions'
import {
	setFavoritesProjectsCount,
	setFavoritesProjectsSum,
	setFavoritesPurchasesCount,
	setFavoritesPurchasesSum,
} from '@root-gipro/modules/favorites/store/actions'
import { setUserProjects } from '../../userProjects/store/actions'
import { fetchData } from '@root-gipro/store/api'
import { revertFilterValue } from '@root-gipro/modules/filter/helpers/filter-state-convert'
import { showNotify } from '@root-gipro/modules/notify/store/actions'
import { encode } from '@root-gipro/utils/helpers/queryString'
import { IUrlsSearch } from '@root-gipro/modules/filter/models/interfaces/filter-state.model'
import { updateUrlSearch } from '@root-gipro/modules/filter/utils/updateUrlSearch'

function* refreshFilterCountResult(action: any) {
	const state = store.getState().filter

	const currentFilter =
		action.url === 'purchases'
			? state.purchases
			: action.url === 'purchases/table'
			? state.purchases
			: action.url === 'common-projects'
			? state.commonProjects
			: action.url === 'common-projects/table'
			? state.commonProjects
			: action.url === 'favorites-common-projects'
			? state.commonProjectsFavorite
			: action.url === 'favorites-common-projects/table'
			? state.commonProjectsFavorite
			: action.url === 'favorites-purchases'
			? state.purchasesFavorite
			: action.url === 'favorites-purchases/table'
			? state.purchasesFavorite
			: action.url === 'user-projects'
			? state.userProjects
			: state.userProjects

	const object = excludeData(currentFilter.showFilterState)
	let clone: any = Object.assign({}, object)

	state.commonProjects.isSimilar && delete clone.name

	const params = {
		...clone,
		...currentFilter.sortState,
	}

	if (state.showFilterResult) {
		const res = yield call(state.showFilterResult, params)
		// надо решить вопрос с обработкой запроса при одновременном указании параметров строительные ресурсы и технические решения! Пока такая заплатка
		if (res) {
			yield put(setPublicUncTechnicalSpecifications(res.uncTechnicalSpecifications)) // для обновления массива uncTechnicalSpecifications при сбросе фильтра

			yield put(setFilterResultCount(res.resultsCnt, action.url))
			yield put(isLoadingCountResult(false))

			if (action.url === 'user-projects') {
				yield put(setFilterResultCount(res.userProjectsCnt, action.url))
				yield put(isLoadingCountResult(false))
			}
		}
	}
}

function* setPresetFilter(action: any) {
	const { regions, groups, uncThs, uncThDescriptions, uncMains, rgStatuses } = store.getState().public
	const { isSelectPresetFilter } = store.getState().filter

	yield put(
		setInitFilterState(
			action.url,
			action.filters,
			revertFilterValue(regions, groups, uncThs, uncThDescriptions, rgStatuses, uncMains, true)
		)
	)
	yield put(isSelectedPresetFilter(!isSelectPresetFilter))

	const urlsSearch: string | null = localStorage.getItem('urlsSearch')
	let urlsSearhParsed: IUrlsSearch = urlsSearch ? JSON.parse(urlsSearch) : {}

	if (!isEmpty(urlsSearhParsed[action.url])) {
		updateUrlSearch(action.url, encode(action.filters))
	}
	yield put(filtersRequest(action.url))
	yield put({ type: FilterActions.SHOW_FILTER_RESULT, url: action.url, filter: true })

	if (action.url === 'purchases' || action.url === 'purchases/table') {
	} else if (action.url === 'common-projects' || action.url === 'common-projects/table') {
		const { name, sortName, order } = store.getState().commonProjects.sort
		yield put(sortCommonProjects(name, sortName, order))
	}
}

function* showFilterResult(action: any) {
	const state = store.getState().filter
	const { sort } = store.getState().commonProjects
	const { sort: sortUserProjectsList } = store.getState().userProjects
	const { favoritesSort } = store.getState().favorites
	const { sortParams, paginationCounter } = store.getState().purchases

	const currentFilter =
		action.url === 'purchases'
			? state.purchases
			: action.url === 'purchases/table'
			? state.purchases
			: action.url === 'common-projects'
			? state.commonProjects
			: action.url === 'common-projects/table'
			? state.commonProjects
			: action.url === 'favorites-common-projects'
			? state.commonProjectsFavorite
			: action.url === 'favorites-common-projects/table'
			? state.commonProjectsFavorite
			: action.url === 'favorites-purchases'
			? state.purchasesFavorite
			: action.url === 'favorites-purchases/table'
			? state.purchasesFavorite
			: action.url === 'user-projects'
			? state.userProjects
			: state.userProjects

	const object = excludeData(currentFilter.showFilterState)
	let cloneObject: any = Object.assign({}, object)
	const width = (currentFilter && currentFilter.sortState?.with) || []
	let params = {}
	if (action.url === 'purchases') {
		params = {
			...object,
			limit: 5,
			with: [...width, 'IDs'],
			order: sortParams.order,
			offset: action.filter ? 0 : paginationCounter,
			...params,
		}
	} else if (action.url === 'purchases/table') {
		params = {
			...object,
			limit: 20,
			with: [...width, 'IDs'],
			order: sortParams.order,
			offset: action.filter ? 0 : paginationCounter,
			...params,
		}
	} else if (action.url === 'favorites-purchases' || action.url === 'favorites-purchases/table') {
		params = {
			// @ts-ignore
			...object,
			limit: 5,
			with: [...width, 'IDs'],
			favoritesOnly: 1,
			order: sortParams.order,
			offset: action.filter ? 0 : paginationCounter,
			...params,
		}
	} else if (action.url === 'common-projects') {
		state.commonProjects.isSimilar && delete cloneObject.name
		params = {
			...cloneObject,
			limit: 10,
			with: [...width, 'IDs'],
			order: (sort.order ? '' : '-') + sort.sortName,
			...params,
		}
	} else if (action.url === 'common-projects/table') {
		params = {
			...object,
			limit: 20,
			with: [...width, 'IDs'],
			order: (sort.order ? '' : '-') + sort.sortName,
			...params,
		}
	} else if (action.url === 'favorites-common-projects' || action.url === 'favorites-common-projects/table') {
		params = {
			// @ts-ignore
			...object,
			limit: 5,
			with: [...width, 'IDs'],
			favoritesOnly: 1,
			order: (favoritesSort.order ? '' : '-') + favoritesSort.sortName,
			...params,
		}
	} else if (action.url === 'user-projects') {
		params = { order: sortUserProjectsList.order, ...object }
	}

	if (state.showFilterResult && state.refreshList && state.toggleLoadingList) {
		yield put(state.toggleLoadingList(true))
		const res = yield call(state.showFilterResult, params)
		if (res?.projects) {
			const projects: ICommonProject[] = res.projects
			const count: number = Number(res.resultsCnt)
			const valuationOfferForecastSum: number = Number(res.valuationOfferForecastSum) / 10 ** 6
			const valuationPlanForecastSum: number = Number(res.valuationPlanForecastSum) / 10 ** 6
			const uncInfo: IUncInfo = res.uncInfo
			const IDs: string[] = res.IDs
			// с бека приходит объект данных, но на клиенте хранятся массивы
			//и итерируются методами для массивов, поэтому сделана эта заплатка
			if (uncInfo.voltageValues) {
				const newVoltageValues = Object.values(uncInfo.voltageValues)
				uncInfo.voltageValues = newVoltageValues
			} else {
				uncInfo.voltageValues = ['0.4', '35', '110', '220', '330', '500', '750']
			}
			if (action.url === 'favorites-common-projects' || action.url === 'favorites-common-projects/table') {
				yield put(setFavoritesProjectsSum(valuationOfferForecastSum, valuationPlanForecastSum))
				yield put(setFavoritesProjectsCount(count))
				yield put(state.refreshList(projects, false))
				yield put(state.toggleLoadingList(false))
			} else {
				yield put(setCommonProjectsSum(valuationOfferForecastSum, valuationPlanForecastSum))
				yield put(state.refreshList(projects, false))
				yield put(setAllowMoreProjects(true))
				yield put(setCommonProjectsCount(count))
				yield put(setCommonProjectsPaginationCount(0))
				yield put(setUncInfo(uncInfo))
				yield put(state.toggleLoadingList(false))
				yield put(setFilterIdsCommonProjects(IDs))
			}
		} else if (res?.purchases) {
			const count: number = Number(res.resultsCnt)
			const purchases: ICommonProject[] = res.purchases
			const maximumPriceSum = Number(res.maximumPriceSum) / 10 ** 6
			if (action.url === 'favorites-purchases' || action.url === 'favorites-purchases/table') {
				yield put(state.refreshList(purchases, false))
				yield put(setFavoritesPurchasesSum(maximumPriceSum))
				yield put(setFavoritesPurchasesCount(count))
			} else {
				yield put(state.refreshList(res, false))
				yield put(setFilterIdsPurchases(res.IDs))
			}
		} else if (res?.userProjects) {
			yield put(setUserProjects(res.userProjects, res.totalInvestmentUncOfferSum, res.userProjectsCnt))
		} else {
			// с бека приходит объект данных, но на клиенте хранятся массивы
			//и итерируются методами для массивов, поэтому сделана эта заплатка
			if (res.uncInfo.voltageValues) {
				res.uncInfo.voltageValues = Object.values(res.uncInfo.voltageValues)
			} else {
				res.uncInfo.voltageValues = ['0.4', '35', '110', '220', '330', '500', '750']
			}
		}
	}
}

function* handleChangeFilterSaga(action: any) {
	yield put(filtersRequest(action.url))
}

function* getPresetFilters(action: any) {
	try {
		const response = yield call(fetchData, '/user-filters', (res: any) => res, 'GET')
		if (!response) {
			console.error('Failed to fetch user filters')
			return
		}

		if (!response.userFilters) {
			console.error('Failed to fetch user filters. "userFilters" property missing from response.')
			return
		}

		yield put(setPresetFilterState(response.userFilters))
	} catch (err) {
		console.error('Error fetching user filters:', err)
	}
}


export function* savePresetFilter({
	name,
	filters,
}: {
	type: FilterActions.SAVE_PRESET_FILTER
	name: string
	filters: any
}) {
	try {
		yield call(fetchData, '/user-filters', (response: any) => response, 'POST', JSON.stringify({ name, filters }))
		yield put({ type: FilterActions.GET_PRESET_FILTERS })
		yield put(
			showNotify({
				type: 'success',
				message: `Фильтр ${name} успешно сохранен!`,
			})
		)
	} catch (error) {
		console.error(error)
	}
}

export function* disabledSimilarProjectSearch({}: { type: FilterActions.DISABLED_SIMILAR_PROJECT_FILTER_STATE }) {
	const url = 'common-projects'

	try {
		yield put(isSimilarProjectFilterState(false))
		yield put(resetFilterState(url))
		yield put(filtersRequest(url))
		yield put(cleanUncCodeFromFilter())
	} catch (error) {
		console.error(error)
	}
}

export function* setSimilarProjectSearch({
	commonProjectId,
	projectName,
}: {
	commonProjectId: string
	projectName: string | undefined
	type: FilterActions.SET_SIMILAR_PROJECT_SEARCH
}) {
	const convertPlug = (fieldName: string, value: any) => (
		fieldName: string,
		value: any
	): { fieldName: string; value: any } => ({ fieldName, value })
	const url = 'common-projects'

	try {
		yield put(resetFilterState(url))
		yield put(isSimilarProjectFilterState(true))
		yield put(
			refreshFilterState('projectSimilarId', commonProjectId, url, convertPlug('projectSimilarId', commonProjectId))
		)
		yield put(setSimilarProjectName(projectName as string))
	} catch (error) {
		console.error(error)
	}
}

export function* editPresetFilter({
	id,
	name,
	filters,
}: {
	type: FilterActions.EDIT_PRESET_FILTER
	id: string
	name: string
	filters: any
}) {
	try {
		if (!id) {
			throw new Error('Invalid ID for editing filter')
		}

		const response = yield call(
			fetchData,
			`/user-filters/${id}`,
			(res: any) => res,
			'PATCH',
			JSON.stringify({ name })
			// для изменения настроек предфильтра передаем filters,но пока такойго кейса не было
		)

		if (!response) {
			throw new Error('Failed to edit filter')
		}

		yield put({ type: FilterActions.GET_PRESET_FILTERS })
		yield put(
			showNotify({
				type: 'success',
				message: `Фильтр ${name} успешно отредактирован!`,
			})
		)
	} catch (error) {
		console.error(error)
	}
}

function* deletePresetFilters({ id }: { type: FilterActions.DELETE_PRESET_FILTERS; id: string }) {
	yield call(fetchData, `/user-filters/${id}`, (res: any) => res, 'DELETE')
	yield put({ type: FilterActions.GET_PRESET_FILTERS })
}


function* getIprVersions(action: any) {
	try {
		const response = yield call(fetchData, '/iprVersion', (res: any) => res, 'GET')
		if (!response) {
			console.error('Failed to fetch user filters')
			return
		}

		yield put(setVersionIprList(response.iprVersion))
	} catch (err) {
		console.error('Error fetching user filters:', err)
	}
}

export default function* watchFilter() {
	yield takeEvery(FilterActions.SHOW_FILTER_RESULT, showFilterResult)
	yield debounce(500, FilterActions.REFRESH_FILTER_STATE, handleChangeFilterSaga)
	yield takeLatest(FilterActions.FILTER_REQUEST, refreshFilterCountResult)
	yield takeLatest(FilterActions.GET_PRESET_FILTERS, getPresetFilters)
	yield takeLatest(FilterActions.SAVE_PRESET_FILTER, savePresetFilter)
	yield takeLatest(FilterActions.EDIT_PRESET_FILTER, editPresetFilter)
	yield takeLatest(FilterActions.DELETE_PRESET_FILTERS, deletePresetFilters)
	yield takeLatest(FilterActions.SET_PRESET_FILTERS, setPresetFilter)
	yield takeLatest(FilterActions.SET_SIMILAR_PROJECT_SEARCH, setSimilarProjectSearch)
	yield takeLatest(FilterActions.DISABLED_SIMILAR_PROJECT_FILTER_STATE, disabledSimilarProjectSearch)
	yield takeLatest(FilterActions.GET_VERSION_IPR_LIST, getIprVersions)

}
