import { Chip, Tooltip, Typography } from '@material-ui/core'
import {
	getCommonProjects,
	loadingCommonProjects,
	setCommonProjects,
} from '@root-gipro/modules/commonProjects/store/actions'
import { convertFilterValue } from '@root-gipro/modules/filter/helpers/filter-state-convert'
import {
	deleteUncCodeFromFilter,
	filtersRequest,
	getVersionIprList,
	refreshFilterState,
	setCallbacks,
	setFilterSortParams,
	showFilterResult as showFilterResultHandler,
} from '@root-gipro/modules/filter/store/actions'
import { formatedDates } from '@root-gipro/modules/userProjects/utils/helpers'
import { commonProjectsApi } from '@root-gipro/store/api'
import { IState, IUncCell, IUncCells } from '@root-gipro/store/interfaces'
import { prettyCost } from '@root-gipro/utils/helpers/common'
import { isNil } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

const actualData = [
	{ number: 1, name: 'Только актуальные проекты' },
	{ number: 2, name: 'Все проекты' },
	{ number: 0, name: 'Только не актуальные проекты' },
]
const FilterCloud = () => {
	const location = useLocation()
	const searchUrl: string = location.search

	const url: string = location.pathname.slice(1)
	const dispatch = useDispatch()

	let { rgStatuses, minMax } = useSelector((state: IState) => state.public)

	rgStatuses = [
		...rgStatuses,
		{
			id: '',
			title: 'Запрос ИД', // доработка, с бэка не прилетает данный элемент
		},
	]

	const convertPlug = (fieldName: string, value: any) => (
		fieldName: string,
		value: any
	): { fieldName: string; value: any } => ({ fieldName, value })

	const { uncCodes } = useSelector((state: IState) => state.filter)
	const { filter } = useSelector((state: IState) => state)
	const publicInfo = useSelector((state: IState) => state.public)
	const { isSimilar } = useSelector((state: IState) => state.filter.commonProjects)
	const { versionIprList } = useSelector((state: IState) => state.filter)
	
	const currentFilter =
		url === 'purchases'
			? filter.purchases
			: url === 'purchases/table'
			? filter.purchases
			: url === 'common-projects'
			? filter.commonProjects
			: url === 'common-projects/table'
			? filter.commonProjects
			: url === 'favorites-common-projects'
			? filter.commonProjectsFavorite
			: url === 'favorites-purchases'
			? filter.purchasesFavorite
			: url === 'user-projects'
			? filter.userProjects
			: filter.userProjects

	let { resultFilterState } = useSelector((state: IState) => currentFilter)

	const [localResultFilterState, setLocalResultFilterState] = useState<{ [key: string]: string | string[] | number }>(
		{}
	)

	const params = {
		limit: 10,
		with: [
			'resultsCnt',
			'valuationOfferForecastSum',
			'valuationPlanForecastSum',
			'projects',
			'uncInfo',
			'uncTechnicalSpecifications',
		],
	}
	const [refreshCloud, setRefreshCloud] = useState<boolean>(false)

	useEffect(() => {
		setLocalResultFilterState(resultFilterState)
	}, [searchUrl])

	useEffect(() => {
		if (refreshCloud) {
			setLocalResultFilterState(resultFilterState)
		}
		setRefreshCloud(false)
	}, [resultFilterState])

	const deleteChip = (fieldName: string, value: any) => {
		setRefreshCloud(true)
		dispatch(refreshFilterState(fieldName, value, url, convertPlug(fieldName, value)))
		dispatch(setCallbacks(getCommonProjects, commonProjectsApi,getVersionIprList, setCommonProjects, loadingCommonProjects))
		dispatch(setFilterSortParams(params, url))
		dispatch(filtersRequest(url))
		dispatch(showFilterResultHandler(url, true))

		setLocalResultFilterState(prev => {
			const updatedPrev = { ...prev }
			delete updatedPrev[fieldName]
			return updatedPrev
		})
	}

	const deleteChipWithArr = (fieldName: string, currValue: string) => {
		setRefreshCloud(true)
		// @ts-ignore
		const newVal = resultFilterState[fieldName]?.filter(el => {
			return el !== currValue
		})

		dispatch(
			refreshFilterState(
				fieldName,
				newVal,
				url,
				convertFilterValue(
					publicInfo.regions,
					publicInfo.groups,
					publicInfo.company,
					publicInfo.uncThs,
					publicInfo.uncThDescriptions,
					rgStatuses,
					publicInfo.uncMains,
					versionIprList
				)
			)
		)
		dispatch(setCallbacks(getCommonProjects, commonProjectsApi, getVersionIprList,setCommonProjects, loadingCommonProjects))
		dispatch(setFilterSortParams(params, url))
		dispatch(filtersRequest(url))
		dispatch(showFilterResultHandler(url, true))

		setLocalResultFilterState(resultFilterState)
	}

	const deleteChipRange = (fieldNameMin: any, fieldNameMax: any) => {
		if (fieldNameMin === null || fieldNameMax === null) {
			console.error('Field names cannot be null')
			return
		}

		setRefreshCloud(true)
		// @ts-ignore
		dispatch(refreshFilterState(fieldNameMin, null, url, convertPlug(fieldNameMin, null)))
		// @ts-ignore
		dispatch(refreshFilterState(fieldNameMax, null, url, convertPlug(fieldNameMax, null)))

		if (dispatch === null) {
			console.error('Dispatch cannot be null')
			return
		}

		dispatch(setCallbacks(getCommonProjects, commonProjectsApi, getVersionIprList,setCommonProjects, loadingCommonProjects))
		dispatch(setFilterSortParams(params, url))
		dispatch(filtersRequest(url))
		dispatch(showFilterResultHandler(url, true))
	}

	const { uncMains } = useSelector((state: IState) => state.public)

	const getTableName = (item: string) => {
		const elem: any = uncCodes?.find(i => Number(i.id) === Number(item))?.code
		if (elem) {
			const [uncCode] = elem?.split('-')
			return uncMains.find(unc => unc.code === uncCode)
		}
	}
	return (
		<>
			{localResultFilterState.code && (
				<Tooltip title='Идентификатор'>
					<Chip onDelete={() => deleteChip('code', '')} label={localResultFilterState.code} />
				</Tooltip>
			)}
			{!isSimilar && localResultFilterState.name ? (
				<Tooltip title='Наименование'>
					<Chip onDelete={() => deleteChip('name', '')} label={localResultFilterState.name} />
				</Tooltip>
			) : null}
			{localResultFilterState.existsPIR ? (
				<Chip onDelete={() => deleteChip('existsPIR', false)} label={'Проектно-изыскательские работы'} />
			) : null}
			{localResultFilterState.withPurchases ? (
				<Chip onDelete={() => deleteChip('withPurchases', false)} label={'Есть связанные закупки'} />
			) : null}
			{localResultFilterState.withPassports ? (
				<Chip onDelete={() => deleteChip('withPassports', false)} label={'Есть паспорт'} />
			) : null}
			{localResultFilterState.existsUNC ? (
				<Chip onDelete={() => deleteChip('existsUNC', false)} label={'Содержат компонентную базу'} />
			) : null}
			{localResultFilterState.groupId
				? //@ts-ignore
				  localResultFilterState.groupId.map((item: string) => (
						<Tooltip title='Организация' key={item}>
							<Chip onDelete={() => deleteChipWithArr('groupId', item)} label={item} />
						</Tooltip>
				  ))
				: null}
			{localResultFilterState.regionId
				? //@ts-ignore
				  localResultFilterState.regionId.map((item: string) => (
						<Tooltip title='Регион' key={item}>
							<Chip onDelete={() => deleteChipWithArr('regionId', item)} label={item} />
						</Tooltip>
				  ))
				: null}
			{localResultFilterState['yearBegin.min'] && localResultFilterState['yearEndOffer.max'] ? (
				<Tooltip title='Период'>
					{(minMax && localResultFilterState['yearBegin.min'] !== `${minMax.yearBegin.min}-01-01`) ||
					(minMax && localResultFilterState['yearEndOffer.max'] !== `${minMax.yearEndOffer.max}-12-31`) ? (
						<Chip
							onDelete={() => deleteChipRange('yearBegin.min', 'yearEndOffer.max')}
							label={
								formatedDates(localResultFilterState['yearBegin.min'] as string) +
								' - ' +
								formatedDates(localResultFilterState['yearEndOffer.max'] as string)
							}
						/>
					) : (
						<div></div>
					)}
				</Tooltip>
			) : null}
			{
				//@ts-ignore
				localResultFilterState['estimatedOfferYM.min'] && localResultFilterState['estimatedOfferYM.max'] ? (
					//@ts-ignore
					<Tooltip title='Дата добавления'>
						{
							//@ts-ignore
							(minMax &&
								localResultFilterState['estimatedOfferYM.min'] !==
									`${minMax.estimatedOfferYM.min.split('-')[0]}-01-01`) ||
							(minMax &&
								localResultFilterState['estimatedOfferYM.max'] !==
									`${minMax.estimatedOfferYM.max.split('-')[0]}-12-31`) ? (
								<Chip
									onDelete={() => deleteChipRange('estimatedOfferYM.min', 'estimatedOfferYM.max')}
									label={
										formatedDates(localResultFilterState['estimatedOfferYM.min'] as string) +
										' - ' +
										formatedDates(localResultFilterState['estimatedOfferYM.max'] as string)
									}
								/>
							) : (
								<div></div>
							)
						}
					</Tooltip>
				) : null
			}
			{
				//@ts-ignore
				!isNil(localResultFilterState['actual']) && localResultFilterState['actual'] !== 1 ? (
					//@ts-ignore
					<Tooltip title='Актуальность'>
						<Chip
							onDelete={() => deleteChip('actual', 1)}
							label={actualData.find(i => i.number === Number(localResultFilterState['actual']))?.name}
						/>
					</Tooltip>
				) : null
			}
			{
				//@ts-ignore
				localResultFilterState['uncCellId']
					? //@ts-ignore
					  localResultFilterState['uncCellId'].split(',').map((item: string) =>
							//@ts-ignore
							getTableName(item)?.name ? (
								<Tooltip
									title={
										<>
											<Typography>
												<span className='selected_item__info_title'>Компонентная база</span>
											</Typography>
											<Typography>
												<span className='selected_item__info_title'>Таблица: </span>
												<span>{getTableName(item)?.name}</span>
											</Typography>
										</>
									}
								>
									<Chip
										onDelete={() => {
											dispatch(
												//@ts-ignore
												deleteUncCodeFromFilter((uncCodes as IUncCell[]).find(i => Number(i.id) === Number(item)))
											)
											deleteChip(
												'uncCellId',
												(localResultFilterState['uncCellId'] as string)
													.split(',')
													.filter((i: string) => i !== item)
													.join(',')
											)
										}}
										label={uncCodes.find(i => Number(i.id) === Number(item))?.code}
									/>
								</Tooltip>
							) : null
					  )
					: null
			}
			{/* в зависимости от радиокнопок */}
			{
				//@ts-ignore
				(localResultFilterState['estimatedOfferCurrent.min'] ||
					localResultFilterState['estimatedOfferCurrent.min'] === 0) &&
				localResultFilterState['estimatedOfferCurrent.max'] ? (
					//@ts-ignore
					<Tooltip title='Полная сметная стоимость согласно ПСД (с НДС)'>
						<Chip
							onDelete={() => deleteChipRange('estimatedOfferCurrent.min', 'estimatedOfferCurrent.max')}
							label={
								prettyCost(Number(localResultFilterState['estimatedOfferCurrent.min']) / 10 ** 6) +
								' млн.₽' +
								' - ' +
								prettyCost(Number(localResultFilterState['estimatedOfferCurrent.max']) / 10 ** 6) +
								' млн.₽'
							}
						/>
					</Tooltip>
				) : null
			}
			{
				//@ts-ignore
				(localResultFilterState['valuationUncOfferForecast.min'] ||
					localResultFilterState['valuationUncOfferForecast.min'] === 0) &&
				localResultFilterState['valuationUncOfferForecast.max'] ? (
					//@ts-ignore
					<Tooltip title='Оценка полной стоимости по УНЦ'>
						<Chip
							onDelete={() => deleteChipRange('valuationUncOfferForecast.min', 'valuationUncOfferForecast.max')}
							label={
								prettyCost(Number(localResultFilterState['valuationUncOfferForecast.min']) / 10 ** 6) +
								' млн.₽' +
								' - ' +
								prettyCost(Number(localResultFilterState['valuationUncOfferForecast.max']) / 10 ** 6) +
								' млн.₽'
							}
						/>
					</Tooltip>
				) : null
			}
			{
				//@ts-ignore
				(localResultFilterState['valuationOfferForecast.min'] ||
					localResultFilterState['valuationOfferForecast.min'] === 0) &&
				localResultFilterState['valuationOfferForecast.max'] ? (
					//@ts-ignore
					<Tooltip title='Оценка полной стоимости по УНЦ'>
						<Chip
							onDelete={() => deleteChipRange('valuationOfferForecast.min', 'valuationOfferForecast.max')}
							label={
								prettyCost(Number(localResultFilterState['valuationOfferForecast.min']) / 10 ** 6) +
								' млн.₽' +
								' - ' +
								prettyCost(Number(localResultFilterState['valuationOfferForecast.max']) / 10 ** 6) +
								' млн.₽'
							}
						/>
					</Tooltip>
				) : null
			}

			{
				//@ts-ignore
				(localResultFilterState['balanceOffer.min'] || localResultFilterState['balanceOffer.min'] === 0) &&
				localResultFilterState['balanceOffer.max'] ? (
					//@ts-ignore
					<Tooltip title='Оценка в прогнозных ценах'>
						<Chip
							onDelete={() => deleteChipRange('balanceOffer.min', 'balanceOffer.max')}
							label={
								prettyCost(Number(localResultFilterState['balanceOffer.min']) / 10 ** 6) +
								' млн.₽' +
								' - ' +
								prettyCost(Number(localResultFilterState['balanceOffer.max']) / 10 ** 6) +
								' млн.₽'
							}
						/>
					</Tooltip>
				) : null
			}
			{/* в зависимости от радиокнопок */}
			{
				//@ts-ignore
				localResultFilterState['uncTechnicalSpecification.name']
					? //@ts-ignore
					  localResultFilterState['uncTechnicalSpecification.name'].map((item: string) => (
							<Tooltip title='Строительные ресурсы (Наименование)' key={item}>
								<Chip onDelete={() => deleteChipWithArr('uncTechnicalSpecification.name', item)} label={item} />
							</Tooltip>
					  ))
					: null
			}
			{
				//@ts-ignore
				localResultFilterState['uncTechnicalSpecification.paramName']
					? //@ts-ignore
					  localResultFilterState['uncTechnicalSpecification.paramName'].map(
							(item: string) =>
								//@ts-ignore
								// <Tooltip title='Строительные ресурсы (Параметр)' key={Math.random()}>
								//@ts-ignore
								item !== '-' && (
									<Chip onDelete={() => deleteChipWithArr('uncTechnicalSpecification.paramName', item)} label={item} />
								)
							// </Tooltip>
					  )
					: null
			}
			{
				//@ts-ignore
				localResultFilterState['uncTechnicalSpecification.param']
					? //@ts-ignore
					  localResultFilterState['uncTechnicalSpecification.param'].map(
							(item: string) =>
								//@ts-ignore
								// <Tooltip title='Строительные ресурсы (Значение)' key={item}>
								//@ts-ignore
								item !== '-' && (
									<Chip onDelete={() => deleteChipWithArr('uncTechnicalSpecification.param', item)} label={item} />
								)
							// </Tooltip>
					  )
					: null
			}
			{
				//@ts-ignore
				(localResultFilterState['unc.count.min'] || localResultFilterState['unc.count.min'] === 0) &&
				localResultFilterState['unc.count.max'] ? (
					//@ts-ignore
					<Tooltip title='Стоимость'>
						<Chip
							onDelete={() => deleteChipRange('unc.count.min', 'unc.count.max')}
							label={localResultFilterState['unc.count.min'] + ' - ' + localResultFilterState['unc.count.max']}
						/>
					</Tooltip>
				) : null
			}
			{/* Технические решения */}
			{
				//@ts-ignore
				localResultFilterState['uncCell.uncThId']
					? //@ts-ignore
					  localResultFilterState['uncCell.uncThId'].map((item: string) => (
							//@ts-ignore
							<Tooltip title='Технические решения (Наименование)' key={item}>
								<Chip onDelete={() => deleteChipWithArr('uncCell.uncThId', item)} label={item} />
							</Tooltip>
					  ))
					: null
			}
			{
				//@ts-ignore
				localResultFilterState['uncCell.uncTextParameterId']
					? //@ts-ignore
					  localResultFilterState['uncCell.uncTextParameterId'].map((item: string) => (
							// @ts-ignore
							<Tooltip title='Технические решения (Параметры)' key={item}>
								<Chip onDelete={() => deleteChipWithArr('uncCell.uncTextParameterId', item)} label={item} />
							</Tooltip>
					  ))
					: null
			}
			{
				//@ts-ignore
				localResultFilterState['uncCell.voltageValue']
					? //@ts-ignore
					  localResultFilterState['uncCell.voltageValue'].map((item: string) => (
							// @ts-ignore
							<Tooltip title='Технические решения (Напряжение)' key={item}>
								<Chip onDelete={() => deleteChipWithArr('uncCell.voltageValue', item)} label={item} />
							</Tooltip>
					  ))
					: null
			}
			{
				//@ts-ignore
				localResultFilterState['uncCell.uncMainId']
					? //@ts-ignore
					  localResultFilterState['uncCell.uncMainId'].map((item: string) => (
							// @ts-ignore
							<Tooltip title='Технические решения (Таблица)' key={item}>
								<Chip onDelete={() => deleteChipWithArr('uncCell.uncMainId', item)} label={item} />
							</Tooltip>
					  ))
					: null
			}
			{
				//@ts-ignore
				localResultFilterState['uncCell.number']
					? //@ts-ignore
					  localResultFilterState['uncCell.number'].map((item: string) => (
							// @ts-ignore
							<Tooltip title='Технические решения (Строка)' key={item}>
								<Chip onDelete={() => deleteChipWithArr('uncCell.number', item)} label={item} />
							</Tooltip>
					  ))
					: null
			}
			{
				//@ts-ignore
				localResultFilterState['uncCell.subNumber']
					? //@ts-ignore
					  localResultFilterState['uncCell.subNumber'].map((item: string) => (
							// @ts-ignore
							<Tooltip title='Технические решения (Столбец)' key={item}>
								<Chip onDelete={() => deleteChipWithArr('uncCell.subNumber', item)} label={item} />
							</Tooltip>
					  ))
					: null
			}
		</>
	)
}

export default FilterCloud
