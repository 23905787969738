import { TableCell, TableRow, Theme, Tooltip, Typography, createStyles, makeStyles } from '@material-ui/core'
import React, { FC } from 'react'
import { ICommonProject } from '../../models/interfaces/common-projects.model'
import { useHistory } from 'react-router-dom'
import { Routes } from '@root-gipro/router'
import { useDispatch } from 'react-redux'
import { prettyCost } from '@root-gipro/utils/helpers/common'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		bodyCell: {
			textAlign: 'center',
			maxWidth: '200px', // Limit cell width
			whiteSpace: 'nowrap', // Prevent text from wrapping
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
		row: {
			'&:nth-of-type(odd)': {
				backgroundColor: theme.palette.action.hover,
			},
			'&:hover': {
				backgroundColor: '#d4d4d480',
			},
		},
	})
)

interface TableBodyRowProps {
	row: ICommonProject
	company: string
	getProjectById(id: string | number): void
	region: string
}

const TableBodyRowCommonProject: FC<TableBodyRowProps> = ({ row, company, getProjectById, region }) => {
	const routePath = Routes.COMMON_PROJECTS_PARAMETERS.replace(/:id/g, row.id)
	const classes = useStyles()
	const history = useHistory()

	const dispatch = useDispatch()
	const handleBtn = () => {
		history.push(routePath)
		// getProjectById && getProjectById(row.id)
		// dispatch(setTargetProjectId(row.id))
	}
	return (
		<TableRow key={row.id} className={classes.row} onClick={handleBtn}>
			<TableCell className={classes.bodyCell}>{row.id}</TableCell>
			<TableCell className={classes.bodyCell}>
				<Tooltip title={row.code}>
					<Typography noWrap>{row.code}</Typography>
				</Tooltip>
			</TableCell>
			<TableCell className={classes.bodyCell}>
				<Tooltip title={company}>
					<Typography noWrap>{company ? company : '-'}</Typography>
				</Tooltip>
			</TableCell>
			<TableCell className={classes.bodyCell}>
				<Tooltip title={row.name}>
					<Typography noWrap>{row.name}</Typography>
				</Tooltip>
			</TableCell>
			<TableCell className={classes.bodyCell}>
				<Tooltip title={`${row.yearBegin} - ${row.yearEndOffer}`}>
					<Typography noWrap>{`${row.yearBegin} - ${row.yearEndOffer}`}</Typography>
				</Tooltip>
			</TableCell>
			<TableCell className={classes.bodyCell}>
				<Tooltip title={region}>
					<Typography noWrap>{region}</Typography>
				</Tooltip>
			</TableCell>
			<TableCell className={classes.bodyCell}>
				<Tooltip title={row.valuationOfferForecast}>
					<Typography noWrap>
						{row.valuationOfferForecast ? prettyCost(row.valuationOfferForecast / 10 ** 6) : '-'}
					</Typography>
				</Tooltip>
			</TableCell>
			<TableCell className={classes.bodyCell}>
				<Tooltip title={row.publicationDate}>
					<Typography noWrap>{row.publicationDate}</Typography>
				</Tooltip>
			</TableCell>
			<TableCell className={classes.bodyCell}>
				<Tooltip title={row.estimatedOfferCurrent}>
					<Typography noWrap>
						{row.estimatedOfferCurrent ? prettyCost(row.estimatedOfferCurrent / 10 ** 6) : 0}
					</Typography>
				</Tooltip>
			</TableCell>
			<TableCell className={classes.bodyCell}>
				<Tooltip title={row.valuationUncOfferForecast ? row.valuationUncOfferForecast : 0}>
					<Typography noWrap>
						{row.valuationUncOfferForecast ? prettyCost(row.valuationUncOfferForecast / 10 ** 6) : 0}
					</Typography>
				</Tooltip>
			</TableCell>
			<TableCell className={classes.bodyCell}>
				<Tooltip title={row.valuationUncOfferForecast}>
					<Typography noWrap>
						{row?.valuationUncOfferForecast || row?.valuationOfferForecast
							? prettyCost((row?.valuationUncOfferForecast - row?.valuationOfferForecast) / 10 ** 6)
							: 0}
					</Typography>
				</Tooltip>
			</TableCell>
			<TableCell className={classes.bodyCell}>
				<Tooltip title={row.publicationDate}>
					<Typography noWrap>{row.publicationDate}</Typography>
				</Tooltip>
			</TableCell>
		</TableRow>
	)
}

export default TableBodyRowCommonProject
