import { IFilterState } from '@root-gipro/modules/filter/models/interfaces/filter-state.model'

export const filterState: IFilterState = {
	commonProjects: {
		resultFilterState: {},
		showFilterState: {},
		isSimilar: false,
	},
	commonProjectsFavorite: {
		resultFilterState: {},
		showFilterState: {},
	},
	purchases: {
		resultFilterState: {},
		showFilterState: {},
	},
	purchasesFavorite: {
		resultFilterState: {},
		showFilterState: {},
	},
	userProjects: {
		resultFilterState: {},
		showFilterState: {},
	},

	commonProjectsRates: {
		countStart: '',
		countEnd: '',
		name: [],
		params: [],
		voltage: [],
		table: [],
		row: [],
		column: [],
	},
	loadingCountResult: false,
	isShowFilterCloud: false,
	uncCodes: [],
	isFilterUncsModalState: false,
	isFilterConfigureModalState: false,
	configures: { x: '', id: '' },
	isActualFilter: 1,
	isOpenPresetFilterModal: false,
	modePresetFilterModal: null,
	dataPresetFilter: [],
	isSelectPresetFilter: false,
	isSelectIdPresetFilter: null,
	versionIprList: []
}
